<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">명의변경일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  type="lookup-condition"
                  v-model="changedDateRange"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">회원변경명단</div>
              <div class="header-caption">[0건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      @click.native="onPrintButtonClicked"
                  >
                    인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onExcelButtonClicked"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="grid"
                v-bind="gridOptions"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDateRange from "@/components/common/datetime/InputDateRange.vue";
// import InputText from "@/components/common/text/InputText";
// import CustomShortcutButton from "@/components/button/CustomShortcutButton";
import ErpButton from "@/components/button/ErpButton.vue";
// import {SHORTCUT_KEYS} from "@/utils/KeyboardUtil";
import {ExcelExport, Filter, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
// import GolfErpAPI from "@/api/v2/GolfErpAPI";
// import { orderBy as _orderBy, maxBy as _maxBy, minBy as _minBy, groupBy as _groupBy, } from "lodash";

export default {
  name: "MemberChangeList",
  components: {
    InputDateRange,
    ErpButton,
    EjsGridWrapper,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      searchOptions: {
        changedDateFrom: null,
        changedDateTo: null,
      },
    };
  },
  created() {},
  computed: {
    isPopupOpened() {
      return false;
    },
    changedDateRange: {
      get() {
        return {
          from: this.searchOptions.changedDateFrom,
          to: this.searchOptions.changedDateTo,
        };
      },
      set(dateRange) {
        this.searchOptions.changedDateFrom = dateRange.from;
        this.searchOptions.changedDateTo = dateRange.to;
      }
    },
    gridOptions() {
      return {
        provides: [
          Filter,
          Resize,
          Page,
          ExcelExport,
          ForeignKey,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        allowExcelExport: true,
        allowPaging: true,
        noColumnWidth: 40,
        pageSettings: { pageSize: 50 },
        columns: [
          {
            field: "",
            headerText: "",
            type: "string",
            width: 90,
          },
        ],
      };
    },
  },
  mounted() {},
  methods: {
    onViewButtonClicked() {},
    onPrintButtonClicked() {},
    onExcelButtonClicked() {},
  },
};
</script>
